import { FC, useRef } from "react";
import pkg from "../../../package.json";
import "./AboutScreen.css";
import close from "./close.svg";
import { PageData } from "../../CaseDataInterfaces";
import { BodyContent } from "../bodyContent/BodyContent";
import Logos from "../logos/Logos";
import { Config, useConfig } from "../../config";

interface Props {
  pageData: PageData;
  onClose: () => void;
}

export const AboutScreen: FC<Props> = ({ pageData, onClose }) => {
  const { config, setConfig } = useConfig();

  const dev = useRef({
    time: -1,
    clicks: 0,
  }).current;

  return (
    <div className="about-screen">
      <div className="about-screen-content">
        <BodyContent parts={pageData.body} />
        <div
          className="body-content"
          style={{ userSelect: "none" }}
          onClick={() => {
            const time = Date.now();
            const dt = time - dev.time;
            if (dt < 200) {
              dev.clicks += 1;
              dev.time = time;
            } else {
              dev.clicks = 1;
              dev.time = time;
            }

            if (dev.clicks >= 5) {
              dev.clicks = 0;
              setConfig((config) => {
                return {
                  ...config,
                  devMode: !config.devMode,
                };
              });
            }
          }}
        >
          <h2>Game versie</h2>
          <p>{pkg.version}</p>
          {config.devMode && (
            <p>
              <DebugBtn label="Toon case-list" id="showCasesList" />
              <br />
              <DebugBtn label="Toon test-feedback" id="showTestFeedback" />
            </p>
          )}
        </div>
      </div>
      <button
        className="about-screen-close-button"
        onClick={() => {
          onClose();
        }}
      >
        <img src={close} alt="sluiten" />
      </button>
      <Logos />
    </div>
  );
};

function DebugBtn({ label, id }: { label: string; id: keyof Config }) {
  const { config, setConfig } = useConfig();

  const isSelected = config[id];

  return (
    <label className="about-screen-debugbtn">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => {
          if (isSelected) {
            localStorage.removeItem(id);
          } else {
            localStorage.setItem(id, "1");
          }

          setConfig((config) => {
            return {
              ...config,
              [id]: !isSelected,
            };
          });
        }}
      />{" "}
      {label}
    </label>
  );
}
