import { FC } from "react";
import "./CaseReview.css";
import CareLoadMeter from "../careLoadMeter/CareLoadMeter";
import { PatientData } from "../../CaseDataInterfaces";
import takeawayIcon from "./takeaway.svg";
import { FeedbackLine } from "../../game";

interface Props {
  patient: PatientData;
  urgencyComparison: number; // correct - actual (but remember, numerical lower value is _higher_ urgency
  takeawayMessage: string;
  feedback?: FeedbackLine[];
  defaultActions: JSX.Element;
  retryActions?: JSX.Element;
}

function buildFeedback(tops: JSX.Element[], tips: JSX.Element[]) {
  return (
    <div className="case-review__feedback">
      {tops.length > 0 ? (
        <>
          <h2>Dit heb je goed gedaan:</h2>
          <div>{tops}</div>
        </>
      ) : undefined}
      {tips.length > 0 ? (
        <>
          <h2>Dit had beter gekund:</h2>
          <div>{tips}</div>
        </>
      ) : undefined}
    </div>
  );
}

const CaseReview: FC<Props> = ({
  patient,
  urgencyComparison,
  takeawayMessage,
  feedback,
  defaultActions,
  retryActions,
}) => {
  const takeawayContent = (
    <div className="case-review__takeaway">
      <img src={takeawayIcon} alt="takeaway" />
      {takeawayMessage}
    </div>
  );

  let feedbackContent;

  if (feedback) {
    let tops = feedback
      .filter((value) => value.type === "top")
      .map((top) => (
        <div className="case-review__feedback__tip-top">
          👍&emsp;&emsp;{top.line}
        </div>
      ));
    let tips = feedback
      .filter((value) => value.type === "tip")
      .map((top) => (
        <div className="case-review__feedback__tip-top">
          👎&emsp;&emsp;{top.line}
        </div>
      ));
    feedbackContent = buildFeedback(tops, tips);
  } else {
    // 20240610 as discussed with Rose Anne, we'll show nothing instead of a message that there is no feedback.
    // This is because there actually _is_ feedback just below this block: The 'take away' message
    feedbackContent = undefined;
  }

  // 3 options: U too low, correct, too high. NOTE: a higher number for U is for something _less_ urgent
  if (urgencyComparison > 0) {
    return (
      <div className="case-review case-review__care">
        <h1>Hoe heb je gespeeld?</h1>
        <div className="case-review-summary">
          <div className="case-review-summary-item">
            <h2>
              Geholpen patiënten{" "}
              <span className="case-review__plus_one">+1</span>
            </h2>
            <div className="case-review-patient">
              <img src={patient.photo} alt="pasfoto patiënt" />
            </div>
          </div>
          <div className="case-review-summary-item">
            <h2>Je hebt de zorg onnodig belast</h2>
            <div className="case-review-care-load">
              <CareLoadMeter score="worse" size="medium" />
            </div>
          </div>
        </div>
        {feedbackContent}
        {takeawayContent}
        {retryActions}
        {defaultActions}
      </div>
    );
  } else if (urgencyComparison < 0) {
    return (
      <div className="case-review case-review__care case-review__urgency">
        <h1>Hoe heb je gespeeld?</h1>
        <div className="case-review__urgency-messages">
          Helaas, je hebt de patiënt niet juist geholpen,
          <br />
          want je hebt de urgentie te laag ingeschat.
        </div>
        <div className="case-review-summary-item">
          <h2>Geholpen patiënten</h2>
          <span className="case-review__plus_zero">+0</span>
        </div>
        {feedbackContent}
        {takeawayContent}
        {defaultActions}
      </div>
    );
  } else {
    return (
      <div className="case-review case-review__care">
        <h1>Hoe heb je gespeeld?</h1>
        <div className="case-review-summary">
          <div className="case-review-summary-item">
            <h2>
              Geholpen patiënten{" "}
              <span className="case-review__plus_one">+1</span>
            </h2>
            <div className="case-review-patient">
              <img src={patient.photo} alt="pasfoto patiënt" />
            </div>
          </div>
          <div className="case-review-summary-item">
            <h2>Je hebt de zorg juist belast</h2>
            <div className="case-review-care-load">
              <CareLoadMeter score="good" size="medium" />
            </div>
          </div>
        </div>
        {feedbackContent}
        {takeawayContent}
        {defaultActions}
      </div>
    );
  }
};

export default CaseReview;
