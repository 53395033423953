import {FC, useEffect, useMemo, useRef, useState} from "react";
import {Question, UrgencyType} from "../../CaseDataInterfaces";
import './Case.css';
import {UrgencyBar} from "../urgencyBar/UrgencyBar";
import {useCaseRunner, useCaseRunnerViewModel} from "../../CaseRunnerContextProvider";
import PatientInfo from "../patientInfo/PatientInfo";
import AudioPlayer from "../audioPlayer/AudioPlayer";
import Notification from "../notification/Notification";
import {ICaseRunnerDebug, InteractionPhase} from "../../CaseRunner";
import OptionButton from "../optionButton/OptionButton";
import QuestionButton from "../questionButton/QuestionButton";
import HeaderBar from "../headerBar/HeaderBar";
import {CaseResult, GameState, getCurrentCasesFromState} from "../../game";
import isDevMode from "../../util/isDevMode";
import Button from "../button/Button";
import {PhoneButton} from "../phoneButton/PhoneButton";
import LevelStatus from "../levelStatus/LevelStatus";
import CaseEnd from "../caseEnd/CaseEnd";
import ItemOptionsTable from "../itemOptionsTable/ItemOptionsTable";
import CaseViewModel, {NotificationModel} from "../../CaseViewModel";
import {PhoneIcon} from "../phoneIcon/PhoneIcon";
import PhoneRinger from "../phoneRinger/PhoneRinger";
import useHasChanged from "../../util/useHasChanged";
// import { useInView } from 'react-intersection-observer';
import useEventListener from "../../util/usehooks-ts/useEventListener";
import MinMaxToggleButton from "../minMaxToggleButton/MinMaxToggleButton";
import { useConfig } from "../../config";

interface CaseProps {
    stopFn: () => void;
    nextFn: () => void;
    caseResultFn: (result: CaseResult) => void;
    gameState?: GameState;
    analyticsSourceRef?: string;
}
export const Case: FC<CaseProps> = ({stopFn, nextFn, caseResultFn, gameState, analyticsSourceRef}) => {
    const {config} = useConfig();

    const caseRunner = useCaseRunner();
    const viewModel = useCaseRunnerViewModel();
    const questionSet = useMemo(() => {
        return ([] as Question[]).concat(
            viewModel.questionSet1Active ? viewModel.questionSet1 : [],
            viewModel.questionSet2Active ? viewModel.questionSet2 : [],
            viewModel.questionSet3Active ? viewModel.questionSet3 : [],
        );
    }, [viewModel.questionSet1Active, viewModel.questionSet2Active, viewModel.questionSet3Active,
        viewModel.questionSet1, viewModel.questionSet2, viewModel.questionSet3]);
    const anyQuestionSetActive = viewModel.questionSet1Active || viewModel.questionSet2Active || viewModel.questionSet3Active;
    const unhandledNotifications = viewModel.notifications.filter(value => value.new);
    const [showInfoForItem, setShowInfoForItem] = useState<NotificationModel|undefined>();
    // const [urgencyTitleRef, urgencyTitleInView] = useInView();
    const [urgencyChange, setUrgencyChange] = useState<{before: UrgencyType, now: UrgencyType}|undefined>();

    const phaseDidChange = useHasChanged(viewModel.phase);
    useEffect(() => {
        if (phaseDidChange ) {
            window.scrollTo({
                top: 0,
                // behavior: 'smooth' // <-- some issues, and also makes it necessary to delay the 'in' animation for questions
            });
        }
    });

    const finishCase = () => {
        // use state from callback, as the 'component wide' state will only be updated on the next render
        caseRunner.handleAction({type: "hang-up-phone", payload: Date.now()}, (viewModel) => {
            // send results back to 'main game'
            // Maybe this could also be triggered from caseRunner internally?
            caseResultFn({
                caseId: viewModel.caseId,
                timeSeconds: Math.floor(((viewModel.endTime ?? 0) - (viewModel.startTime ?? 0))  / 1000),
                timestamp: Date.now(),
                urgencyValue: viewModel.urgency,
                urgencyTargetValue: viewModel.correctUrgency,
                errorCount: viewModel.notifications.filter(value => value.type === 'mistake').length,
                feedback: viewModel.feedback,
            });
        });
    };

    useEffect(() => {
        const unloadCallback = (event:BeforeUnloadEvent) => {
            // calling preventDefault() will trigger a warning for the user that information might not have been saved
            if (viewModel.phase !== 'incoming-call' && viewModel.phase !== 'case-state-ended') {
                if (isDevMode()) {
                    console.log('production version would warn about closing window now...')
                } else {
                    event.preventDefault();
                }
            }
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [viewModel.phase]);

    // define DebugPanel in-line so we can access all local variables
    const [showDebugPanel, setShowDebugPanel] = useState<'hidden'|'semi'|'visible'>('semi');
    const DebugPanel: FC = () => {
        const debugCaseRunner = caseRunner as ICaseRunnerDebug;

        return <div className={'case-debug-panel case-debug-panel__' + showDebugPanel}
                    onClick={() => {
                        setShowDebugPanel((value) => {
                            if (value === 'semi') {
                                return 'visible';
                            }
                            if (value === 'visible') {
                                return 'semi';
                            }
                            return value;
                        })
                    }}>
            <div>case: {viewModel.caseId}</div>
            <div><button onClick={(event) => {event.stopPropagation(); debugCaseRunner.debug_reset()}}>reset case</button></div>
            <div>Jump to:</div>
            {
                ['context', 'incoming-call', 'case-state-complaint', 'case-state-criteria', 'case-state-end', 'case-state-ended'].map((phase, phaseIdx) => <div key={phaseIdx}><button

                    onClick={(event) => {event.stopPropagation(); debugCaseRunner.debug_handleSetStep(phase as InteractionPhase)}}>{phase}
                </button></div>)
            }
            <div>Presets:</div>
            <div><button onClick={(event) => {event.stopPropagation(); debugCaseRunner.debug_fillAbcd()}}>fill abcd</button></div>
            <div><button onClick={(event) => {event.stopPropagation(); debugCaseRunner.debug_fillCriteria()}}>fill criteria</button></div>
            <div>Correct urgency: {viewModel.correctUrgency}</div>
            {
                [0,1,2,3,4,5].map((value) => {
                    return <div key={value}>
                        <button onClick={(event) => {
                            event.stopPropagation();
                            caseRunner.handleAction({type: "DEBUG_cause_urgency", payload: value});
                        }}>Select U{value}
                        </button>
                    </div>
                })
            }
            <div><button onClick={(event) => {
                event.stopPropagation();
                // reset game state for the next round
                debugCaseRunner.debug_reset();
                stopFn();
            }}>close (no save)</button></div>
        </div>;
    }

    // show debug panel if hidden
    useEventListener('keypress', (event) => {
        if (event.key === 'd') {
            setShowDebugPanel((value) => {
                if (value === 'hidden') {
                    return 'visible';
                }
                return 'hidden';
            });
        } else if (event.key === 'D') {
            setShowDebugPanel('hidden');
        }
    })

    let phoneButton = <></>;
    if (viewModel.phoneState === 'ringing') {
        phoneButton = <><PhoneButton
            type='off-hook'
            onClick={() => caseRunner.handleAction({type: "answer-phone", payload: Date.now()})}
        /><PhoneRinger ring={true} /></>;
    }
    // else if (viewModel.phoneState === 'talking-end') {
    //     phoneButton = <PhoneButton
    //         type='on-hook'
    //         onClick={() => {finishCase()}}
    //     />;
    // }

    let mainContent;

    const showUrgencyUpdateTimerRef = useRef<ReturnType<typeof setTimeout>>();
    function checkShowUpdatedUrgency(updatedViewModel: CaseViewModel) {
        if (viewModel.urgency !== updatedViewModel.urgency) {
            console.log('updated urgency', viewModel.urgency, updatedViewModel.urgency);
            if (showUrgencyUpdateTimerRef.current !== undefined) {
                clearTimeout(showUrgencyUpdateTimerRef.current);
            }
            showUrgencyUpdateTimerRef.current = setTimeout(() => {
                console.log('clearing urgency');
                setUrgencyChange(undefined);
            }, 2000);
            setUrgencyChange({before: viewModel.urgency, now: updatedViewModel.urgency});
        }
    }

    if (viewModel.phase === 'context') {
        mainContent = <div className="case-panel case-panel-context">
            <div className='case-panel-context__content'>{viewModel.context}</div>
            <div className='case-next-phase-button-container'>
                <Button type='primary'
                        onClick={() => {
                            caseRunner.handleAction({
                                type: 'next-phase', payload: Date.now()
                            })
                        }}>Verder</Button>
            </div>
            {
                config.showTestFeedback ?
                    <div className='case-panel-context__test-feedback'>
                        <h3>Feedback Tekstcheck</h3>
                        {
                            (caseRunner as ICaseRunnerDebug).debug_getAllFeedback().map((item, idx) => {
                                return <div key={idx}>
                                    <b>{item.context}</b><br/>
                                    👍 {item.feedback.positive}<br/>
                                    👎 {item.feedback.negative}<br/><br/>
                                </div>
                            })
                        }
                        {
                            (caseRunner as ICaseRunnerDebug).debug_getAllFeedback().length === 0 ?
                                'Geen feedback gevonden in deze casus'
                                :
                                undefined
                        }
                    </div>
                    :
                    undefined
            }
        </div>
    } else if (viewModel.phase === 'case-state-ended') {
        // when call has ended, show end screen

        const currentCases = getCurrentCasesFromState(gameState);
        // all levels are either completed or they are the current level with a sufficient urgency
        const levelComplete = currentCases.every((caseState) => {
            return caseState.state === 'completed'
                ||
                (
                    caseState.caseId === viewModel.caseId
                    &&
                    viewModel.correctUrgency >= viewModel.urgency
                )
        })
        mainContent = viewModel.patient ?
            <CaseEnd
                patient={viewModel.patient}
                urgencyComparison={viewModel.correctUrgency - viewModel.urgency}
                exitFn={() => {
                    stopFn();
                }}
                nextFn={nextFn}
                retryFn={() => {
                    caseRunner.handleAction({type: 'reset', payload: undefined});
                    // gtag event
                    gtag("event", "case_play_again", {
                        case_id: viewModel.caseId,
                        source_ref: analyticsSourceRef,
                    });
                }}
                levelComplete={levelComplete}
                takeawayMessage={viewModel.takeaway}
                feedback={viewModel.feedback}
            />
            :
            undefined
    } else {
        mainContent = <>
            {/*main wrap*/}
            <div className='case-panel case-panel-left' >
                <div className='case-call-panel'>
                    <PhoneIcon type={viewModel.phoneState} />
                    {
                        viewModel.phoneState === 'talking' || viewModel.phoneState === 'talking-end' ?
                            <AudioPlayer audio={viewModel.audio} />
                            :
                            undefined
                    }
                    {phoneButton}
                </div>

                {
                    anyQuestionSetActive ?
                        <div className='case-question-list-wrap'>
                            <h5 className='case-block-header'>Kies de juiste vragen/reacties</h5>
                            <div className="case-question-list">
                                {
                                    questionSet.map((qOption, qOptionIdx) => {
                                        return <div key={qOption.id}
                                                    className='case-question-list-item'
                                                    style={{animationDelay: `${qOptionIdx * 50}ms`}}
                                        >
                                            <QuestionButton model={qOption} main={true} onClick={() => {
                                                caseRunner.handleAction({type: "select-question", payload: qOption.text})
                                            }}/>
                                            <div className="case-followup-question-list" >
                                                {
                                                    viewModel.activeQuestionOptionChoices[qOption.text] ?
                                                        qOption.followups.map((qFollowUpOption) => {
                                                            return <QuestionButton key={qFollowUpOption.id}
                                                                                   model={qFollowUpOption}
                                                                                   main={false}
                                                                                   onClick={() => {
                                                                                       caseRunner.handleAction({
                                                                                           type: "select-question",
                                                                                           payload: qFollowUpOption.text
                                                                                       })
                                                                                   }}/>
                                                        })
                                                        :
                                                        undefined
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        :
                        undefined
                }
            </div>
            <div className='case-panel case-panel-right' >
                {/*right column*/}

                {
                    viewModel.phase !== "incoming-call" ?
                        <>
                            <div className='case-patient'>
                                {
                                    viewModel.patient ?
                                        <PatientInfo patient={viewModel.patient}></PatientInfo>
                                        :
                                        undefined
                                }
                            </div>
                            <div className={'case-urgency' + (urgencyChange ? ' case-urgency__overlay' : '')}>
                                <h5 className='case-block-header'>Urgentie</h5>
                                <div className='case-block'>
                                    <UrgencyBar selected={viewModel.urgency}></UrgencyBar>
                                </div>
                            </div>
                            {
                                viewModel.abcdItemsActive ?
                                    <>
                                        <h5 className='case-abcd case-block-header'>ABCD <MinMaxToggleButton
                                            minimized={viewModel.abcdItemsMinimized}
                                            onClick={() => {
                                                caseRunner.handleAction({
                                                    type: 'minimize-abcd', payload: !viewModel.abcdItemsMinimized
                                                })
                                            }
                                            }/></h5>
                                        {!viewModel.abcdItemsMinimized ?
                                            <ItemOptionsTable
                                                items={viewModel.abcdItems}
                                                itemOptionChoices={viewModel.abcdItemOptionChoices}
                                                onItemClickFn={(itemText, optionText) => {
                                                    caseRunner.handleAction({
                                                        type: 'select-abcd', payload: {
                                                            item: itemText,
                                                            option: optionText
                                                        }
                                                    }, (updatedViewModel) => {
                                                        // check, should we show urgency update?
                                                        checkShowUpdatedUrgency(updatedViewModel);
                                                    })
                                                }}
                                                onItemInfoClickFn={(item) => {
                                                    setShowInfoForItem({
                                                        type: 'item-info',
                                                        title: item.text,
                                                        body: item.info ?? '',
                                                        new: true,
                                                        id: Math.random().toString(),
                                                    })
                                                }}
                                            />
                                            :
                                            <div className='case-block case-abcd-collapsed'></div>
                                        }
                                    </>
                                    :
                                    undefined
                            }
                            <div className='case-chief-complaint'>
                                {
                                    viewModel.entryComplaintOptionsActive ?
                                        <>
                                            <h5 className='case-block-header'>Ingangsklacht(en)</h5>
                                            <table className='case-entry-complaints-table'>
                                                <tbody>
                                                {
                                                    viewModel.entryComplaintOptions.map((qOption, qOptionIdx) => {
                                                        return <tr key={qOptionIdx}>
                                                            <td>
                                                                <OptionButton
                                                                    selected={viewModel.entryComplaintOptionChoices[qOption.id]}
                                                                    onClick={() => {
                                                                        caseRunner.handleAction({
                                                                            type: 'select-complaint',
                                                                            payload: qOption.text
                                                                        });
                                                                    }}
                                                                    option={qOption}
                                                                />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </>
                                        :
                                        undefined
                                }
                                {
                                    viewModel.triageCriteriaActive ?
                                        <>
                                            <h5 className='case-block-header'>Triage Criteria</h5>
                                            <ItemOptionsTable
                                                items={viewModel.triageCriteria}
                                                itemOptionChoices={viewModel.triageCriteriaOptionChoices}
                                                onItemClickFn={(itemText, optionText) => {
                                                    caseRunner.handleAction({
                                                        type: 'select-criterium', payload: {
                                                            item: itemText,
                                                            option: optionText
                                                        }
                                                    }, (updatedViewModel) => {
                                                        // check, should we show urgency update?
                                                        checkShowUpdatedUrgency(updatedViewModel);
                                                    })
                                                }}
                                                onItemInfoClickFn={(item) => {
                                                    setShowInfoForItem({
                                                        type: 'item-info',
                                                        title: item.text,
                                                        body: item.info ?? '',
                                                        new: true,
                                                        id: Math.random().toString(),
                                                    })
                                                }}
                                            />
                                        </>
                                        :
                                        undefined
                                }
                            </div>
                        </>
                        :
                        undefined
                }

                {/*overlay state*/}
                {/*{*/}
                {/*    urgencyChange && !urgencyTitleInView ?*/}
                {/*        <div className='case-urgency-change-overlay'>*/}
                {/*            <div className='case-block'>*/}
                {/*                <UrgencyBar selected={urgencyChange.now}></UrgencyBar>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        undefined*/}
                {/*}*/}
            </div>
        </>;
    }

    return (<div className='case'>
         <HeaderBar caseState={viewModel} renderLevelStatus={() => {
             if (gameState !== undefined) {
                 return <LevelStatus level={(gameState.levelIdx ?? 0) + 1} cases={getCurrentCasesFromState(gameState).map((caseState, caseStateIdx) => {
                     return caseStateIdx === gameState?.caseIdx ?
                         {...caseState, state: "active"}
                         :
                         caseState;
                 })} />;
             } else {
                 return undefined;
             }
         }}/>
            <div className={(unhandledNotifications.length === 0 && showInfoForItem === undefined) ? 'case-panel-wrap' : 'case-panel-wrap case-panel-wrap__disabled'}>
                {mainContent}
                {
                    viewModel.nextPhaseButtonStatus !== 'hidden' ?
                        <div className='case-next-phase-button-container'>
                            <Button disabled={viewModel.nextPhaseButtonStatus === 'disabled'} type='primary'
                                    onClick={() => {
                                        if (viewModel.phoneState === 'talking-end') {
                                            finishCase();
                                        } else {
                                            caseRunner.handleAction({
                                                type: 'next-phase', payload: undefined
                                            })
                                        }
                                    }}>{viewModel.phase !== 'case-state-end' ? 'Verder' : 'Ophangen'}</Button>
                        </div>
                        :
                        undefined
                }
            </div>
        {
            showInfoForItem ?
                <div className='case-dialog-overlay'>
                    <Notification model={showInfoForItem} onOk={() => {
                        setShowInfoForItem(undefined);
                    }} />
                </div>
                :
                undefined
        }
        {
            unhandledNotifications.length > 0 ?
                <div className='case-dialog-overlay'>
                    <Notification model={unhandledNotifications[0]} onOk={() => {
                        caseRunner.handleAction({
                            type: "notification-handled",
                            payload: unhandledNotifications[0].id
                        })
                    }
                    }></Notification>
                </div>
                :
                undefined
        }
        {
            isDevMode() ?
                <DebugPanel />
                :
                undefined
        }
    </div>)
}