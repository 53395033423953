import React, {FC} from "react";
import "./ItemOptionsTable.css";
import {Item} from "../../CaseDataInterfaces";
import OptionButton from "../optionButton/OptionButton";
import InfoButton from "../infoButton/InfoButton";
import { useConfig } from "../../config";

interface ItemLineProps {
    model: Item;
    selectedOption?: string;
    onOptionClick: (optionValue: string) => void;
    onInfoClick: () => void;
}
const ItemLine: FC<ItemLineProps> = ({
                                         model,
                                         selectedOption,
                                         onOptionClick,
                                         onInfoClick,
}) => {
    const {config} = useConfig();

    let testElement = undefined;
    if (config.showTestFeedback) {
        let hasFeedback = model.feedback !== undefined;
        testElement = hasFeedback ? <>{' '}<span className='option-button__debug-feedback'> [{hasFeedback ? ' + feedback' : ''}]{ }</span></> : undefined
    }
    return (
        <>
            <td className='item-options-table-item-info'>{
                model.info !== undefined ?
                    <div className='item-options-table-item-info-inner-wrap'><InfoButton onClick={onInfoClick} /></div>
                    :
                    undefined
            }</td>
            <td className='item-options-table-item-name'>{model.text}{testElement}</td>
            <td className='item-options-table-item-options'>
                <div className='item-options-table-item-options-inner-wrap'>
                    {
                        model.options.map((option, optionIdx) => {
                            const selected = selectedOption === option.text;
                            return <OptionButton key={optionIdx}
                                                 selected={selected}
                                                 onClick={() => {onOptionClick(option.text);}}
                                                 option={option}
                            />
                        })
                    }
                </div>
            </td>
        </>
    )
}

interface Props {
    items: Item[];
    itemOptionChoices: {[key: string]: string};
    onItemClickFn: (itemText: string, optionText: string) => void;
    onItemInfoClickFn: (item: Item) => void;
}
const ItemOptionsTable: FC<Props> = ({
                                         items,
                                         itemOptionChoices,
                                         onItemClickFn,
                                         onItemInfoClickFn
}) => {
    return <table className='item-options-table'>
        <tbody>
        {
            items.map((item, itemIdx) => {
                return <tr key={itemIdx}>
                    <ItemLine
                        model={item}
                        selectedOption={itemOptionChoices[item.text]}
                        onOptionClick={(optionValue) => {onItemClickFn(item.text, optionValue)}}
                        onInfoClick={() => {onItemInfoClickFn(item)}}
                    />
                </tr>
            })
        }
        </tbody>
    </table>
}
export default ItemOptionsTable;