import React, {FC} from "react";
import "./OptionButton.css";
import {ItemOption} from "../../CaseDataInterfaces";
import { useConfig } from "../../config";

interface Props {
    selected: boolean;
    option: ItemOption;
    onClick: (event:React.MouseEvent) => void;
}
const OptionButton: FC<Props> = ({selected, option, onClick}) => {
    const {config} = useConfig();

    let testElement = undefined;
    if (config.showTestFeedback) {
        let hasFeedback = option.feedback !== undefined;
        testElement = <>{' '}<span className='option-button__debug-feedback'> [{option.score}{hasFeedback ? ' + feedback' : ''}]{ }</span></>
    }
    return <button
        className={selected ? 'option-button option-button-selected' : 'option-button'}
        onClick={(evt) => {onClick(evt);}}
    >{option.text} {testElement}</button>
}
export default OptionButton;