import { createContext, ReactNode, useContext, useState } from "react";

export type Config = {
  devMode: boolean;
  showCasesList: boolean;
  showTestFeedback: boolean;
};

const ConfigContext = createContext<null | {
  config: Config;
  setConfig: React.Dispatch<React.SetStateAction<Config>>;
}>(null);

export function ConfigProvider({ children }: { children?: ReactNode }) {
  const [config, setConfig] = useState<Config>(() => {
    return {
      devMode: Boolean(localStorage.devMode),
      showCasesList: Boolean(
        window.location.toString().match(/[?&]caselist=1/) ||
          localStorage.showCasesList
      ),
      showTestFeedback: Boolean(
        window.location.toString().match(/[?&]testfeedback=1/) ||
          localStorage.showTestFeedback
      ),
    };
  });

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const ctx = useContext(ConfigContext);
  if (!ctx) {
    throw new Error("Must wrap with <ConfigProvider>");
  }

  return ctx;
}
