import {FC, useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import './App.css';
import {GameContent} from './CaseDataInterfaces';
import {Case} from "./components/case/Case";
import CaseRunner from "./CaseRunner";
import CaseRunnerContextProvider, {
  CaseIdToCaseDataMappingContext,
  CaseIdToPhotoMappingContext,
} from "./CaseRunnerContextProvider";
import LoadingScreen from "./components/loadingScreen/LoadingScreen";
import GameScreen from "./components/gameScreen/GameScreen";
import Button from "./components/button/Button";
import {
    CaseResult,
    gameReducerBuilder,
    GameState,
    getCurrentLevelFromState,
    initialGameState
} from "./game"
import {AboutScreen} from "./components/aboutScreen/AboutScreen";
import {ScreenSizeWarning} from "./components/screenSizeWarning/ScreenSizeWarning";
import isDevMode from "./util/isDevMode";
import LevelEnd from "./components/levelEnd/LevelEnd";
import GameEndScreen from "./components/gameEndScreen/GameEndScreen";
import OverlayDialog from "./components/overlayDialog/OverlayDialog";
import {CaseFeedbackScreen} from "./components/caseFeedbackScreen/CaseFeedbackScreen";
import { useConfig } from './config';

type ThemeType = 'style-alternative-1'|'';
const themes: ThemeType[] = ['', 'style-alternative-1'];
const App: FC<{content: GameContent|null, saveState: (state:GameState) => void, savedState?: GameState, version?: string, analyticsSourceRef?: string}> = ({content, saveState, savedState, version = undefined, analyticsSourceRef}) => {
  const {config} = useConfig();

    const cases = content?.cases ?? null;
    const [singleCaseTest, setSingleCaseTest] = useState(false);
  const [theme, setTheme] = useState<ThemeType>('');
  const gameReducer = useMemo(() => {return gameReducerBuilder(content?.level_structure || [])}, [content]);
  const [gameState, gameDispatch] = useReducer(gameReducer, savedState ?? {...initialGameState, compatibility_version: content?.compatibility_version});
  useEffect(() => {
    saveState(gameState);
  }, [gameState, saveState]);

  const [activeCaseIdx, setActiveCaseIdx] = useState<number|null>(null);
  const [displaySizeWarning, setDisplaySizeWarning] = useState<boolean>(false);
  const [resetGameDialog, setResetGameDialog] = useState<boolean>(false);
  const [resetLevelDialog, setResetLevelDialog] = useState<boolean>(false);
  useEffect(() => {
    if (gameState.levelIdx !== undefined && gameState.caseIdx !== undefined) {
      // try to find it in the array stored structure (not in the original GAME_LEVEL_STRUCTURE as the patients could
      // have 'returned' after not being given the right U.
      const caseId = gameState.levels[gameState.levelIdx].caseStates[gameState.caseIdx].caseId;
      const caseIdx = cases?.findIndex((caseData) => {
        return caseData.id === caseId;
      });
      if (caseIdx !== undefined && caseIdx > -1) {
        setActiveCaseIdx(caseIdx);
      }
    }
  }, [gameState.levels, gameState.caseIdx, gameState.levelIdx, cases]);
  const nextTheme = () => {
    setTheme(prevState => {
      const oldIdx = themes.indexOf(prevState);
      if (oldIdx < 0 || oldIdx >= themes.length - 1) {
        return themes[0];
      }
      return themes[oldIdx + 1]
    })
  }
  useEffect(() => {
    const MIN_SIZE_WIDTH = 900;
    const MIN_SIZE_HEIGHT = 650;
    if (window.innerHeight < MIN_SIZE_HEIGHT || window.innerWidth < MIN_SIZE_WIDTH) {
      console.error('Screen size should be', MIN_SIZE_WIDTH, 'x', MIN_SIZE_HEIGHT, 'at minimum, but is', window.innerWidth, 'x', window.innerHeight);
      setDisplaySizeWarning(true);
    }
  }, []);
  const runner = useMemo(() => {
    if (cases && activeCaseIdx !== null) {
      const activeCase = cases[activeCaseIdx];
      return new CaseRunner(activeCase);
    }
  }, [cases, activeCaseIdx]);
  const caseIdToCaseDataMapping = useCallback((caseId: string) => {
    if (cases !== null) {
      const activeCase = cases.find(caseData => caseData.id === caseId);
      if (activeCase !== undefined) {
        return activeCase;
      }
    }
  }, [cases]);
  const caseIdToPhotoMapping = useCallback((caseId: string) => {
    if (cases !== null) {
        const activeCase = cases.find(caseData => caseData.id === caseId);
        if (activeCase !== undefined) {
          return activeCase.patient.photo;
        }
    }
  }, [cases]);

  let mainContent = <LoadingScreen />;
  if (gameState.page === "case" || singleCaseTest) {
    // show case
    if (runner !== undefined && cases !== null) {
      mainContent = (
          <CaseIdToPhotoMappingContext.Provider value={caseIdToPhotoMapping}>
          <CaseRunnerContextProvider caseRunner={runner} cases={cases}>
            <Case
                stopFn={() => {
                    if (singleCaseTest) {
                        setSingleCaseTest(false);
                        gameDispatch({type: 'start'});
                    } else {
                        gameDispatch({type: 'intro'});
                    }
                }}
                nextFn={() => {
                    if (singleCaseTest) {
                        setSingleCaseTest(false);
                        gameDispatch({type: 'start'});
                    } else {
                        runner.debug_reset();
                        gameDispatch({type: 'next-case'});
                    }
                }}
                caseResultFn={(result:CaseResult) => {
                    if (!singleCaseTest) {
                        console.log('storing result', result);
                        // add result to state for current level in current active game
                        gameDispatch({type: 'case-result', payload: result});
                        // gtag event
                        gtag("event", "case_result", {
                            case_id: result.caseId,
                            case_time_seconds: result.timeSeconds,
                            case_u_target_value: result.urgencyTargetValue,
                            case_u_value: result.urgencyValue,
                            case_timestamp: result.timestamp,
                            source_ref: analyticsSourceRef,
                        });
                    }
                }}
                gameState={gameState}
                analyticsSourceRef={analyticsSourceRef}
            ></Case>
          </CaseRunnerContextProvider>
          </CaseIdToPhotoMappingContext.Provider>
      );
    } else {
      mainContent = (<div>Fout: game is niet goed geinitialiseerd</div>);
    }
  } else if (gameState.page === "debug_case_list") {
    // show overview
    if (cases !== null) {
      mainContent = (<div style={{padding: '10rem'}}>
        {
          cases.map((caseData, caseDataIdx) => {
            return <div key={caseDataIdx} style={{marginBottom: '10rem'}}>
              <Button type='primary' onClick={() => {setActiveCaseIdx(caseDataIdx)}}>{caseData.title} ({caseData.patient.name})</Button>
              {/*<div className='case-data-json'>*/}
              {/*  {JSON.stringify(caseData, null, 2)}*/}
              {/*</div>*/}
            </div>;
          })
        }
      </div>);
    }
  } else if (gameState.page === "level-overview") {
    const levelState = getCurrentLevelFromState(gameState);
    const gameComplete = content !== null && (gameState.levelIdx === content.level_structure.length - 1)
    mainContent = cases && levelState ?
        <CaseIdToPhotoMappingContext.Provider value={caseIdToPhotoMapping}>
          <LevelEnd
              nextFn={() => {
                runner?.debug_reset();
                gameDispatch({type: 'next-level'});
              }}
              stopFn={() => {
                gameDispatch({type: 'intro'});
              }}
              retryFn={() => {
                setResetLevelDialog(true);
              }}
              levelState={levelState}
              levelNumber={(gameState.levelIdx ?? 0) + 1}
              gameComplete={gameComplete}
              showAboutGameFn={() => {
                  gameDispatch({type: 'show-about', payload: true});
              }}
              showCaseFeedbackFn={(caseResult: CaseResult) => {
                  gameDispatch({type: 'show-case-feedback', payload: caseResult});
              }}
          />
        </CaseIdToPhotoMappingContext.Provider>
        :
        <div>Fout: game is niet goed geinitialiseerd</div>;
  } else if (gameState.page === "game-overview") {
    mainContent = cases ?
        <CaseIdToPhotoMappingContext.Provider value={caseIdToPhotoMapping}>
            <GameEndScreen
                stopFn={() => {
                    gameDispatch({type: 'intro'});
                }}
                retryFn={() => {
                    setResetGameDialog(true);
                }}
                levels={gameState.levels}
                showCaseFeedbackFn={(caseResult: CaseResult) => {
                    gameDispatch({type: 'show-case-feedback', payload: caseResult});
                }}
            />
        </CaseIdToPhotoMappingContext.Provider>
        :
        <div>Fout: game is niet goed geinitialiseerd</div>;
  } else {
    if (cases !== null) {
        mainContent = <CaseIdToPhotoMappingContext.Provider value={caseIdToPhotoMapping}>
            <GameScreen state={gameState}
                        startPageData={content?.start ?? {body: []}}
                        introPageData={content?.intro ?? {body: []}}
                        dispatch={gameDispatch}
                        startNextCaseFn={() => {
                            runner?.debug_reset();
                            gameDispatch({type: 'next-case'});
                        }}
                        triggerRestartGameFn={() => {
                            setResetGameDialog(true);
                        }}
            />
        </CaseIdToPhotoMappingContext.Provider>;
    }
  }
  // about screen should appear on its own
    if (gameState.about) {
        return <div className={`app ${theme}`}>
            <AboutScreen
            pageData={content?.about ?? {body: []}}
            onClose={() => {
                gameDispatch({type: 'show-about', payload: false});
            }}/>
        </div>
    }
    // case feedback should appear on its own
    if (gameState.caseFeedback) {
        return <CaseIdToCaseDataMappingContext.Provider value={caseIdToCaseDataMapping}>
            <div className={`app ${theme}`}>
            <CaseFeedbackScreen
                caseResult={gameState.caseFeedback}
                onClose={() => {
                    gameDispatch({type: 'show-case-feedback', payload: undefined});
                }}/>
        </div>
        </CaseIdToCaseDataMappingContext.Provider>
    }
  // add overlay warning for small screen
  return (
    <div className={`app ${theme}`} data-app-build-info={version} >
      <CaseIdToCaseDataMappingContext.Provider value={caseIdToCaseDataMapping}>
      {mainContent}
      </CaseIdToCaseDataMappingContext.Provider>
        {
            resetLevelDialog ?
                <OverlayDialog
                    body='Weet je zeker dat je de resultaten van dit level wilt wissen en opnieuw wilt beginnen?'
                    onOk={() => {
                        gameDispatch({type: 'reset-level'});
                        // gtag event
                        gtag("event", "level_play_again", {
                            level_idx: gameState.levelIdx,
                            source_ref: analyticsSourceRef,
                        });
                        setResetLevelDialog(false);
                    }}
                    onCancel={() => {
                        setResetLevelDialog(false);
                    }} />
                :
                undefined
        }
        {
            resetGameDialog ?
                <OverlayDialog
                    body='Weet je zeker dat je alle resultaten wilt wissen en opnieuw wilt beginnen?'
                    onOk={() => {
                        gameDispatch({type: 'reset'});
                        // gtag event
                        gtag("event", "game_play_again", {
                            page: gameState.page,
                            source_ref: analyticsSourceRef,
                        });
                        setResetGameDialog(false);
                    }}
                    onCancel={() => {
                        setResetGameDialog(false);
                    }} />
                :
                undefined
        }
      {
        displaySizeWarning ?
            <ScreenSizeWarning closeFn={() => {setDisplaySizeWarning(false)}} />
            :
            undefined
      }
      {
        isDevMode() ?
            <div className={'app-theme-switcher'}>
              DEBUG:
              <button onClick={() => {nextTheme()}}>switch theme</button>
              {/*<button onClick={() => {setMainPage("debug_case_list")}}>case list</button>*/}
              {/*<button onClick={() => {setMainPage("about")}}>about page</button>*/}
            </div>
            :
            undefined
      }
        {/*<div className="corner-ribbon__inner">*/}
        {/*    <div className="corner-ribbon__ribbon" onClick={() => {*/}
        {/*        alert("Preview versie: \n\nDeze versie van de game is speelbaar, maar nog niet volledig af. Er zullen nog fouten in zitten." +*/}
        {/*            "\n\nDaarnaast is de audio die u hoort is gegenereerd met de computer, ontbreken afbeeldingen en kunnen teksten nog worden aangepast.")*/}
        {/*    }}>Preview versie</div>*/}
        {/*</div>*/}
        {
            config.showCasesList && cases !== null && gameState.page === "intro" && !singleCaseTest ?
                <div className='app-test-list-cases'>
                    <h2>Casussen testen:</h2>
                    {
                        cases.map((caseData, caseDataIdx) => {
                            return <div key={caseDataIdx} style={{marginBottom: '10rem'}}>
                                <button onClick={() => {
                                    setSingleCaseTest(true);
                                    setActiveCaseIdx(caseDataIdx)}}>
                                    {caseData.title} ({caseData.patient.name})
                                </button>
                            </div>;
                        })
                    }
                </div>
                :
                undefined
        }
        {/*<div className='app-version'>{version}</div>*/}
    </div>
  );
}

export default App;
