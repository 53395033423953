import React from "react";
import ReactDOM from "react-dom/client";
import pkg from "../package.json";
import "./index.css";
import App from "./App";
import { GameContent } from "./CaseDataInterfaces";
import { GameState, initialGameState } from "./game";
import AppWrap from "./AppWrap";
import isDevMode from "./util/isDevMode";
import updateAppScaleToWidth from "./util/updateAppScaleToWidth";
import { ConfigProvider } from "./config";
// import reportWebVitals from './reportWebVitals';

console.log("v", pkg.version);

let data: GameContent | null = null;
const CASE_CONTENT_URL = "data/cases-content.json";

fetch(CASE_CONTENT_URL)
  .then(function (response) {
    return response.json();
  })
  .then(function (myJson) {
    //console.log(JSON.stringify(myJson));
    data = myJson;
    // re-render
    renderIt();
  });

const LOCAL_STORAGE_KEY = "triage-game-dev-2";
const storedGameState = localStorage.getItem(LOCAL_STORAGE_KEY);
const gameStateSerializer = (value: GameState) => {
  // erase page value to make the app go back to the start on reload
  return JSON.stringify({ ...value, page: undefined });
};
const gameStateDeserializer = (value: string | null) => {
  if (value === null) {
    return initialGameState;
  }
  return { ...initialGameState, ...JSON.parse(value) };
};

// const debugGameState: GameState = undefined;
// hard coded for testing - is now in cases-content.json for the 'real' version
const GAME_LEVEL_STRUCTURE = [
  ["case_1", "case_5", "case_4", "case_12"],
  ["case_3", "case_7", "case_10", "case_11"],
  ["case_2", "case_6", "case_8", "case_9"],
];
const debugGameState: GameState = {
  page: "start",
  levelIdx: 2,
  caseIdx: undefined,
  levels: [
    {
      caseStates: GAME_LEVEL_STRUCTURE[0].map((caseId, caseIdx) => ({
        caseId,
        state: "completed",
        // state: caseIdx < 3 ? 'completed': 'open'
      })),
      results: GAME_LEVEL_STRUCTURE[0].map((caseId, caseIdx) => ({
        caseId: caseId,
        // urgency: 'correct',
        urgencyTargetValue: 5,
        urgencyValue: 5,
        timeSeconds: 2000,
        timestamp: 1669578443807,
        errorCount: 0,
      })),
    },
    {
      caseStates: GAME_LEVEL_STRUCTURE[1].map((caseId, caseIdx) => ({
        caseId,
        // state: caseIdx < 3 ? 'completed': 'open'
        state: "completed",
      })),
      results: GAME_LEVEL_STRUCTURE[1].map((caseId, caseIdx) => ({
        caseId: caseId,
        // urgency: 'correct',
        urgencyTargetValue: 5,
        urgencyValue: 5,
        timeSeconds: 2000,
        timestamp: 1669578443807,
        errorCount: 0,
      })),
    },
    {
      caseStates: GAME_LEVEL_STRUCTURE[2].map((caseId, caseIdx) => ({
        caseId,
        state: caseIdx < 3 ? "completed" : "open",
        // state: 'completed'
      })),
      results: GAME_LEVEL_STRUCTURE[2].slice(0, -1).map((caseId, caseIdx) => ({
        caseId: caseId,
        // urgency: 'correct',
        urgencyTargetValue: 5,
        urgencyValue: 5,
        timeSeconds: 2000,
        timestamp: 1669578443807,
        errorCount: 0,
      })),
    },
  ],
  about: false,
};

const useDebugGameState = isDevMode();

const version = process.env.REACT_APP_BUILD_INFO;

let params = new URL(document.location.toString()).searchParams;
const analyticsSourceRef = params.get("source_ref") ?? undefined;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
function renderIt() {
  root.render(
    <React.StrictMode>
      <ConfigProvider>
        <AppWrap>
          <App
            content={data}
            saveState={(state: GameState) => {
              localStorage.setItem(
                LOCAL_STORAGE_KEY,
                gameStateSerializer(state)
              );
            }}
            savedState={
              debugGameState &&
              useDebugGameState &&
              process.env.REACT_APP_DEFAULT_STATE
                ? debugGameState
                : gameStateDeserializer(storedGameState)
            }
            version={version}
            analyticsSourceRef={analyticsSourceRef}
          />
        </AppWrap>
      </ConfigProvider>
    </React.StrictMode>
  );
}
renderIt();

// window.addEventListener('resize', () => {updateAppScaleToWidth(window.innerWidth)});
updateAppScaleToWidth(window.innerWidth);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
